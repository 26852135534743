import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource'
import { createLogger } from 'vuex'

import modules from './modules'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'
import { vuexLocalStorage } from './plugins'

const state = {
  order: {},
  displayGiftListBar: true,
  coupon: {},
  user: { id: undefined },
  wishlistItems: [],
  recentlyAdded: [],
  promotions: [],
  locale: window.locale || 'el',
  site_code: window.site_code || null,
  currentCurrency: window.current_currency || {},
  history: [],
  lineItemImageVersions: {},
  wishlistItemImageVersions: {},
  giftLists: [],
  giftListsLoaded: false,
  activeGiftListId: null,
  addressCreatedFromGiftList: false,
  giftCardValue: 0,
  billing_address: {},
  shipping_address: {},
  sameAsBilling: false,
  versions: {
    wishlist: undefined,
    cart: undefined,
    miniCart: undefined
  },
  customAttributesMiniCart: {},
  orderOptions: {},
  hidePriceForProductGroupIds: [],
  addresses: [],
  creditCardLoading: false,
  creditCardResponse: {},
  creditCard: {
    data: {},
    errors: {}
  },
  simplify: {}
}

Vue.use(Vuex)

const isDebugMode = process.env.NODE_ENV !== 'production'
export const store = new Vuex.Store({
  modules,
  state,
  getters,
  actions: { 
    ...actions,
    // resets state of all the modules
    reset({ commit }) {
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`);
      })
    }
  },
  mutations,
  strict: isDebugMode,
  plugins: [...(isDebugMode ? [createLogger()] : []), vuexLocalStorage.plugin]
})
